import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter.js";
import ClearIcon from '@mui/icons-material/Clear';
import { Button, IconButton, Box, Avatar, Typography } from '@mui/material';
import DeviceDetector from "device-detector-js";


const ARButton = (props) => {
    const canvasRef = useRef()
    const canvasContainerRef = useRef()
    const startARRef = useRef()
    const images = props.images
    const { glbUrl, voiceUrl, usdzUrl } = props.appData

    const [arButton, setARButton] = useState(false)

    const ARHandler = () => {
        console.log("enter AR")
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(navigator.userAgent);
        window.dispatchEvent(new CustomEvent('enter-ar', { detail: { os: device.os.name } }));

    }

    useEffect(() => {

        renderScene(images)

    }, [])


    const renderScene = (images) => {

        const canvas = canvasRef.current
        const canvasContainer = canvasContainerRef.current;
        let camera, scene, renderer, controls
        const { XRRay } = window;
        let arScene = new THREE.Group();
        const cubeGrp = new THREE.Group();

        const gltfLoader = new GLTFLoader();

        let animateWeb

        let mixers = []
        // ar 
        let placeMode = true;
        let initPlaced = false;
        let session;
        let localReferenceSpace = null;
        let viewerReferenceSpace = null;
        let hitTestSourceRequested = false;
        let hitTestSource = null;
        let transientHitTestSource = null;

        let raycaster = new THREE.Raycaster();
        let rayCasterProxy;

        let mouse = new THREE.Vector2();
        let offsetDirection = new THREE.Vector4(0, 0, -1, 0);
        const clock = new THREE.Clock()

        let lastAngle = 0;
        let needInitScale = true;
        let xrAnimate

        let initialScale = 1
        let initRatio = 0

        let initScale = 0.4
        let bgSound
        let listener

        placeMode = true;
        initPlaced = false;
        let action


        THREE.DefaultLoadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
            console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
        };

        THREE.DefaultLoadingManager.onLoad = function () {
            console.log('Loading Complete!');
            setARButton(true)
        };


        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 100);
        camera.position.z = 3;
        renderer = new THREE.WebGLRenderer({
            canvas,
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
        });

        renderer.xr.enabled = true;
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.outputEncoding = THREE.sRGBEncoding;
        // canvasContainer.appendChild(renderer.domElement);
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        scene.environment = pmremGenerator.fromScene(
            new RoomEnvironment(),
            0.04
        ).texture;

        const audioLoader = new THREE.AudioLoader()
        listener = new THREE.AudioListener()
        camera.add(listener)
        bgSound = new THREE.Audio(listener)
        audioLoader.load(voiceUrl, (buffer) => {
            bgSound.setBuffer(buffer)
            bgSound.setLoop(true)
            bgSound.setVolume(0.5)
            bgSound.onEnded = () => {
                setTimeout(() => {
                    console.log("vidoe End")
                }, 6000)
            }
        })

        scene.add(arScene)

        // arScene.add(cubeGrp)

        rayCasterProxy = new THREE.Mesh(new THREE.BoxGeometry(initScale, initScale, initScale));
        // rayCasterProxy.visible = false;
        // rayCasterProxy.scale.set(0.09, 0.098, 0.098)

        arScene.add(rayCasterProxy);


        gltfLoader.load(
            glbUrl,  // resource URL.
            (gltf) => {
                // turn on castShadow and put meshes to raycaster group
                gltf.scene.traverse((obj) => {
                    if (obj instanceof THREE.Mesh) {
                        obj.castShadow = true
                    }
                })
                // animation variables
                const mixer = new THREE.AnimationMixer(gltf.scene)
                action = mixer.clipAction(gltf.animations[0])
                mixers.push(mixer)
                // gltf.scene.rotateX(Math.PI / 2)
                // gltf.scene.scale.set(0.3, 0.3, 0.3)
                // gltf.scene.position.set(-0.2, 0.5, 0)
                arScene.add(gltf.scene)
            }
        )


        // console.log(nftTexture)
        controls = new OrbitControls(camera, renderer.domElement);
        controls.update();
        window.addEventListener("enter-ar", async (e) => {
            const os = e.detail.os
            if (os === "iOS") {
                enterIOS()
            } else {
                if (window.navigator.xr) {
                    const isARSupported = await window.navigator.xr.isSessionSupported(
                        "immersive-ar"
                    );
                    if (isARSupported) {
                        enterAndroidAR();
                    } else {
                        console.log("AR not support. no Immersive-AR")
                    }
                } else {
                    console.log("AR not support. No Navigator XR")
                }
            }

            cancelAnimationFrame(animateWeb);
        })

        const enterAndroidAR = async () => {

            if (hitTestSource) {
                hitTestSource.cancel()
                hitTestSource = null
            }
            if (transientHitTestSource) {
                transientHitTestSource.cancel()
                transientHitTestSource = null
            }


            hitTestSourceRequested = false
            console.log("enter webxr api")
            placeMode = true;
            initPlaced = false;
            session = await navigator.xr.requestSession("immersive-ar", {
                requiredFeatures: ["hit-test"],
                optionalFeatures: ["dom-overlay"],
                domOverlay: { root: document.querySelector("#ar-overlay") },
            });

            localReferenceSpace = await session.requestReferenceSpace("local");
            viewerReferenceSpace = await session.requestReferenceSpace("viewer");

            session.addEventListener("end", onSessionEnded);
            session.addEventListener("selectstart", onSelectStart);
            session.addEventListener("selectend", onSelectEnd);

            renderer.xr.setReferenceSpaceType("local");
            await renderer.xr.setSession(session);
            session.requestAnimationFrame(onXRFrame);

            session
                .requestHitTestSourceForTransientInput({ profile: "generic-touchscreen" })
                .then((hitTestSource) => {
                    transientHitTestSource = hitTestSource;
                });
            document.querySelector("#ar-overlay").style.display = "block"
            // 
            document.querySelector("#ar-close-btn").addEventListener("click", async () => {

                if (session) {
                    console.log(hitTestSource)

                    if (hitTestSource) {
                        hitTestSource.cancel()
                        hitTestSource = null
                    }
                    if (transientHitTestSource) {
                        transientHitTestSource.cancel()
                        transientHitTestSource = null
                    }
                    await session.end();
                }


            })

            document.addEventListener(
                "keydown",
                async (event) => {
                    const keyName = event.key;
                    if (keyName === "Escape") {
                        if (session) {

                            if (hitTestSource) {
                                hitTestSource.cancel()
                                hitTestSource = null
                            }
                            if (transientHitTestSource) {
                                transientHitTestSource.cancel()
                                transientHitTestSource = null
                            }
                            await session.end();
                        }
                        return;
                    }
                },
                false
            );


        }

        function fundPlaceFunction() {

            bgSound.play()
            action.play()

        }
        function onSelectStart() {
            lastAngle = 0;
            initialScale = scene.scale.x;
            needInitScale = true;
            hitTestSourceRequested = false;
        }
        function onSelectEnd() {

            hitTestSourceRequested = true;
            placeMode = false;
            mouse.set(0, 0);
        }
        function onSessionEnded(/*event*/) {
            console.log("refresh")



            cancelAnimationFrame(animateWeb);
            session.removeEventListener("end", onSessionEnded);
            session.removeEventListener("selectstart", onSelectStart);
            session.removeEventListener("selectend", onSelectEnd);
            cancelAnimationFrame(xrAnimate);
            scene.position.set(0, 0, 0);
            scene.rotation.set(0, 0, 0);
            scene.scale.set(1, 1, 1);
            camera.position.set(0, 1.56, 2);
            controls.update();
            document.querySelector("#ar-overlay").style.display = "none"
            bgSound.stop()
            action.stop()
            session = null
            initPlaced = false;
            hitTestSourceRequested = false;
            transientHitTestSource = null

        }
        const enterIOS = () => {
            const anchor = document.createElement("a");
            anchor.setAttribute("href", usdzUrl);
            anchor.setAttribute("rel", "ar");
            anchor.appendChild(document.createElement("img"));
            anchor.click();
        }
        function onXRFrame(t, frame) {
            session = frame.session;
            xrAnimate = session.requestAnimationFrame(onXRFrame);
            if (!hitTestSourceRequested) {
                offsetDirection = placeMode
                    ? new THREE.Vector4(mouse.x * 0.35, mouse.y * 0.65, -1, 0).normalize()
                    : new THREE.Vector4(0, 0, -1, 0);

                const XrayOffset = new XRRay(new DOMPoint(0, 0, 0), offsetDirection);

                session
                    .requestHitTestSource({
                        space: viewerReferenceSpace,
                        offsetRay: XrayOffset,
                    })
                    .then((source) => {
                        hitTestSource = source;
                        processInput(frame, source);
                    });
            }

            if (hitTestSource && placeMode) {
                const hitTestResults = frame.getHitTestResults(hitTestSource);
                if (hitTestResults.length) {
                    const hit = hitTestResults[0];
                    const positions = hit.getPose(localReferenceSpace).transform.position;
                    scene.position.copy(positions);
                    if (!initPlaced) {
                        placeMode = false;
                        hitTestSourceRequested = true;
                        initPlaced = true;
                        fundPlaceFunction()
                    }
                }
            }

            const delta = clock.getDelta()
            // three js animation mixer
            if (mixers) {
                mixers.forEach((mixer) => {
                    mixer.update(delta)  // update animations
                })
            }
            renderer.render(scene, camera);
        }


        function processInput(frame, hitSource) {
            if (!transientHitTestSource) {
                return;
            }
            const fingers = frame.getHitTestResultsForTransientInput(
                transientHitTestSource
            );
            if (fingers.length === 2) {
                const { separation, deltaYaw } = fingerPolar(fingers);
                if (Math.abs(deltaYaw) < 0.5) {
                    scene.rotateY(deltaYaw);
                }
                if (needInitScale) {
                    initRatio = separation;
                    needInitScale = false;
                }
            } else if (fingers.length === 1) {
                xrayPosition(fingers);
            }
        }
        function xrayPosition(fingers) {
            mouse.set(
                fingers[0].inputSource.gamepad.axes[0],
                -fingers[0].inputSource.gamepad.axes[1]
            );
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObject(rayCasterProxy, true);
            if (intersects.length > 0) {
                placeMode = true;
                hitTestSourceRequested = false;
            }
        }

        function fingerPolar(fingers) {
            const fingerOne = fingers[0].inputSource.gamepad.axes;
            const fingerTwo = fingers[1].inputSource.gamepad.axes;
            const deltaX = fingerTwo[0] - fingerOne[0];
            const deltaY = fingerTwo[1] - fingerOne[1];
            const angle = Math.atan2(deltaY, deltaX);
            let deltaYaw = lastAngle - angle;
            if (deltaYaw > Math.PI) {
                deltaYaw -= 2 * Math.PI;
            } else if (deltaYaw < -Math.PI) {
                deltaYaw += 2 * Math.PI;
            }
            lastAngle = angle;
            return {
                separation: Math.sqrt(deltaX * deltaX + deltaY * deltaY),
                deltaYaw: deltaYaw,
            };
        }


        // function animate() {
        //     animateWeb = requestAnimationFrame(animate);
        //     controls.update();
        //     renderer.render(scene, camera);

        // }

        // animate()

    }


    return (
        <React.Fragment>

            <Box
                ref={canvasContainerRef}>

            </Box>

            {arButton ?

                <Button variant="contained" ref={startARRef}
                    onClick={ARHandler}
                    sx={{
                        width: "200px",

                    }}
                >Start AR</Button>
                :
                <Button variant="contained"
                    sx={{
                        width: "200px",


                    }}
                >AR Not Support</Button>
            }


            <Box sx={{
                display: "block",
            }}>
                <Box sx={{
                    display: "none",
                }} id="ar-overlay">
                    <IconButton
                        id="ar-close-btn"
                        width="40"
                        height="40"
                        sx={{
                            color: "white",
                            position: 'absolute',
                            margin: "30px",
                            top: "0",
                            right: "0",
                            width: "40px",
                            height: '40px',
                            borderRadius: "40px",
                            fontSize: "1.2rem",
                            textAlign: 'center',
                            backgroundColor: "#6767677a",
                            '&:hover': {
                                boxShadow: "inset 0px 5px 15px rgb(0 0 0 / 8%)",
                            }
                        }}
                    ><ClearIcon /></IconButton>

                </Box>
            </Box>
        </React.Fragment >
    );
};

export default ARButton;