import * as React from "react";
import { CircularProgress, Box, Avatar, Typography, Button } from '@mui/material';

import ARButton from "./Components/ARButton";

const Home = (props) => {

    React.useEffect(() => {



    }, [])

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: "absolute",
                    top: '0',
                    background: "linear-gradient(180deg, #84EECE 0%, #4957DA 48.96%, #A35CFD 100%)",
                    width: "100%",
                    height: "100%",
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "50px"
                }}
            >
                <Typography
                    sx={{
                        fontSize: '1.5rem',
                        color: 'white',
                        textAlign:"center"
                    }}
                >
                    Liquality Tra-vatar Demo
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        src="./assets/images/poster.png"
                        sx={{
                            width: "90px",
                        }}
                    >
                    </Box>
                </Box>

                <ARButton appData={props.appData} />
                <Box
                    component="img"
                    src="./assets/images/powerbyxrgoodcaompany.svg"
                    sx={{
                        position: "absolute",
                        maxWidth: "400px",
                        bottom: '5%',
                        width: "90%",
                    }}
                >
                </Box>
            </Box>
        </React.Fragment >
    );
};
export default Home;