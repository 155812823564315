import React, { useEffect, useState } from 'react';
import './App.css';

import Home from './Home';

function App() {
  const [appData, setAppData] = useState()
  useEffect(() => {
    fetch("./assets/appData.json")
      .then(response => response.json())
      .then(response => {
        console.log(response)
        setAppData(response)
      }
      )
  }, [])
  return (
    <React.Fragment>
      {appData && <Home appData={appData}></Home>}
    </React.Fragment>
  );
}

export default App;
